import React, { Component } from "react";
import { connect } from "react-redux";
import { Dialog, Portal, Button, Paragraph } from "react-native-paper";
import { hideSessionExpiredDialog, setLogoutFlag } from "../../store/actions/uiActions";
import { StyleSheet, View } from "react-native-web";
import { Platform } from "react-native";

class SessionExpiredDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            componentSessionExpiredDialogVisible: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.sessionExpiredDialogVisible !== prevState.componentSessionExpiredDialogVisible) {
            return { componentSessionExpiredDialogVisible: nextProps.sessionExpiredDialogVisible };
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.sessionExpiredDialogVisible && this.props.sessionExpiredDialogVisible) {
            if (this.props.logout) {
                this.props.logout();
            } else {
                console.error("Logout function is not provided");
            }
        }
    }

    handleLogin = () => {
        const { dispatch } = this.props;
        dispatch(setLogoutFlag(true));
        dispatch(hideSessionExpiredDialog());
        this.props.login();
    };

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(hideSessionExpiredDialog());
    };

    render() {
        const { componentSessionExpiredDialogVisible } = this.state;

        return (
            <Portal>
                {componentSessionExpiredDialogVisible && (
                    <View style={styles.container}>
                        <Dialog visible={true} dismissable={false} style={{ maxWidth: "500px", alignSelf: "center" }}>
                            <Dialog.Title>Session abgelaufen</Dialog.Title>
                            <Dialog.Content>
                                <Paragraph style={{ fontFamily: "regular" }}>
                                    Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.
                                </Paragraph>
                            </Dialog.Content>
                            <Dialog.Actions>
                                <Button onPress={this.handleLogin}>Anmelden</Button>
                                <Button onPress={this.handleClose}>Schließen</Button>
                            </Dialog.Actions>
                        </Dialog>
                    </View>
                )}
            </Portal>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        maxHeight: "100vh",
        height: "100vh",
    },
});

const mapStateToProps = (state) => ({
    sessionExpiredDialogVisible: state.uiReducer.sessionExpiredDialogVisible,
    isLoggingOut: state.uiReducer.isLoggingOut,
});

export default connect(mapStateToProps)(SessionExpiredDialog);
